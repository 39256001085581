import React, { useContext, useMemo } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { COLOR, Pill } from '@4r/module-common-ant-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
	nameSorter,
	getStringPropertySorter,
	typeNameSorter,
	getDatePropertySorter,
	getNumberPropertySorter,
} from '@4r/module-common-mf-app';
import { AppointmentModel, OrderDetailsResponse, OrderModel } from '../../api/api';
import getCurrentBooking from '../../common/orders';
import { getOnFilterFn, getPropertyFilters } from '../../common/filters';
import { TIME_FORMAT, DATE_FORMAT, MappedCIs, mapAppointmentToOrderModel, NONE, getNewestBookingUntilDate } from './common';

import ConditionIssuesTooltip from '../../components/ConditionIssueTooltip/ConditionIssuesTooltip';

import OrderListMobile from './OrderListMobile';
import { OfflineMessage } from './OfflineMessage';

import './OrderList.scss';
import { UserContext } from '../../contexts/UserContext';
import { ResourceBookingStatusId } from '../../common/consts';

type OrderListProps = {
	ordersData: OrderDetailsResponse[];
	loading: boolean;
	selectedId: string | null;
	appointments?: AppointmentModel[];
	networkError?: boolean;
	selectedDate: dayjs.Dayjs;
	retry?: () => void;
};



const propertyAddressSorter = getStringPropertySorter<OrderModel>((x) => x.property?.address.street);
const propertyNoSorter = getStringPropertySorter<OrderModel>((x) => x.property?.propertyNo);

const appointmentTimeSorter = getDatePropertySorter<OrderModel>((x) => getCurrentBooking(x.statusId, x.bookings)?.startTime);
const ciNumberSorter = getNumberPropertySorter<OrderModel>((x) => x.numberOfConditionIssues);

const nameFilterAccessor = (x: OrderModel) => x.name;
const typeFilterAccessor = (x: OrderModel) => x.typeName;
const statusFilterAccessor = (x: OrderModel, selectedDate: dayjs.Dayjs, resourceId?: string) => 
	getNewestBookingUntilDate(x, selectedDate, resourceId)?.statusName;
const addressFilterAccessor = (x: OrderModel) => (x.property?.address ? `${x.property.address.city}, ${x.property.address.state}` : null);
const addressIdFilterAccessor = (x: OrderModel) => x.property?.propertyNo;
const appointmentTimeAccessor = (x: OrderModel) => getCurrentBooking(x.statusId, x.bookings)?.startTime;

const OrderList = (props: OrderListProps) => {
	const user = useContext(UserContext);
	const screens = useBreakpoint();
	const isMobileOrTablet = !screens.xl;
	const isMobile = screens.xs;
	const { ordersData, loading, selectedId, appointments, networkError, selectedDate, retry } = props;
	const appointmentsMappedToOrders: OrderModel[] = appointments?.map(mapAppointmentToOrderModel) ?? [];
	const orders = [...ordersData.map((d) => d.order), ...appointmentsMappedToOrders].sort(appointmentTimeSorter);
	const mappedCIs = ordersData.reduce<MappedCIs>(
		(aggr, data) => ({ ...aggr, [data.order.id]: Object.values(data.condititionIssueInstances) }),
		{},
	);
	const nameFilters = useMemo(() => getPropertyFilters(orders, nameFilterAccessor), [orders]);
	const typeFilters = useMemo(() => getPropertyFilters(orders, typeFilterAccessor), [orders]);
	const statusFilters = useMemo(() => getPropertyFilters(orders, (x) => statusFilterAccessor(x, selectedDate, user.current?.resourceId)), [orders]);
	const addressFilters = useMemo(() => getPropertyFilters(orders, addressFilterAccessor), [orders]);
	const addressIdFilters = useMemo(() => getPropertyFilters(orders, addressIdFilterAccessor), [orders]);
	const appointmentTimeFilters = useMemo(() => getPropertyFilters(orders, appointmentTimeAccessor), [orders]);

	const getStatusNameSorter = (item1: OrderModel, item2: OrderModel) => {
		const booking1 = getNewestBookingUntilDate(item1, selectedDate, user.current?.resourceId);
		const booking2 = getNewestBookingUntilDate(item2, selectedDate, user.current?.resourceId);
		if (booking1?.statusName && booking2?.statusName) {
			return booking1.statusName.localeCompare(booking2.statusName);
		}
		return 0;
	}

	if (isMobileOrTablet)
		return (
			<OrderListMobile
				orders={orders}
				mappedCIs={mappedCIs}
				isMobile={isMobile}
				loading={loading}
				networkError={networkError}
				selectedDate={selectedDate}
				retry={retry}
			/>
		);

	return (
		<Table<OrderModel>
			className="order-list-table"
			dataSource={loading ? [] : orders}
			rowKey="id"
			loading={loading}
			pagination={{ hideOnSinglePage: true, style: { paddingRight: '16px' } }}
			rowClassName={(item) => (selectedId === item.id ? 'ant-table-row-selected' : '')}
			locale={networkError ? { emptyText: <OfflineMessage retry={retry} /> } : undefined}
		>
			<Table.Column<OrderModel>
				title="Order"
				dataIndex="name"
				sorter={nameSorter}
				filters={nameFilters}
				onFilter={getOnFilterFn(nameFilterAccessor)}
				render={(_, item) => (item.typeName === NONE ? item.name : <Link to={`/orders/${item.id}`}>{item.name}</Link>)}
			/>
			<Table.Column<OrderModel>
				title="Type"
				dataIndex="typeName"
				sorter={typeNameSorter}
				filters={typeFilters}
				onFilter={getOnFilterFn(typeFilterAccessor)}
			/>
			<Table.Column<OrderModel>
				title="Status"
				dataIndex="bookingStatus"
				sorter={getStatusNameSorter}
				filters={statusFilters}
				onFilter={getOnFilterFn((x) => statusFilterAccessor(x, selectedDate, user.current?.resourceId))}
				render={(_, item) =>
					item.typeName === NONE ? (
						NONE
					) : (
						<div className="order-status-cell">
							<Pill.Generic label={getNewestBookingUntilDate(item, selectedDate, user.current?.resourceId)?.statusName || ''} size="small" fillColor={COLOR.Blue} />
						</div>
					)
				}
			/>
			<Table.Column<OrderModel>
				title="Property Address"
				key="propertyAddress"
				sorter={propertyAddressSorter}
				filters={addressFilters}
				onFilter={getOnFilterFn(addressFilterAccessor)}
				responsive={['lg']}
				render={(_, item) =>
					item.typeName === NONE
						? NONE
						: item.property?.address && (
								<div className="order-status-cell">
									<span>
										{item.property.address.street}, {item.property.address.city}
									</span>
									<span className="additional-description">
										{item.property.address.state} {item.property.address.zipCode}
									</span>
								</div>
						  )
				}
			/>
			<Table.Column<OrderModel>
				title="Property ID"
				dataIndex={['property', 'propertyNo']}
				sorter={propertyNoSorter}
				filters={addressIdFilters}
				onFilter={getOnFilterFn(addressIdFilterAccessor)}
				render={(_, { property }) => property?.propertyNo ?? NONE}
			/>
			<Table.Column<OrderModel>
				title="# of issues"
				sorter={ciNumberSorter}
				render={(record) =>
					record.typeName === NONE ? (
						NONE
					) : (
						<ConditionIssuesTooltip orderId={record.id} conditionIssues={mappedCIs[record.id]} count={record.numberOfConditionIssues} />
					)
				}
				responsive={['lg']}
				width="100px"
			/>
			<Table.Column<OrderModel>
				title="Scheduled"
				sorter={appointmentTimeSorter}
				filters={appointmentTimeFilters}
				onFilter={getOnFilterFn(appointmentTimeAccessor)}
				render={(_, item) => {
					const currentBooking = getCurrentBooking(item.statusId, item.bookings);
					return (
						<div className="order-status-cell">
							<span>{dayjs(currentBooking?.startTime).format(DATE_FORMAT)}</span>
							<span className="additional-description">
								{dayjs(currentBooking?.startTime).format(TIME_FORMAT)} - {dayjs(currentBooking?.endTime).format(TIME_FORMAT)}
							</span>
						</div>
					);
				}}
			/>
		</Table>
	);
};

export default OrderList;
